.page-item:first-child {
    border-top-left-radius: 0.975rem !important;
    border-bottom-left-radius: 0.975rem !important;
    overflow: hidden;
    border: 1px solid $gris;
}

.page-item:last-child {
    border-top-right-radius: 0.975rem !important;
    border-bottom-right-radius: 0.975rem !important;
    overflow: hidden;
    border: 1px solid $gris;
}

.page-item.active .page-link {
    border: 1px solid transparent;
}

/* Notificaciones  */

.notification-error {
    background-color: white !important;
    border-radius: 20px !important;
    border: 2px solid $rojo0a !important;
}

.notification-success {
    background-color: white !important;
    border-radius: 20px !important;
    border: 2px solid $verde !important;
}

.notification-error .notification-message {
    color: $rojo0a !important;
}

.notification-success .notification-message {
    color: $verde !important;
}

.notification-error .notification-message .title {
    color: $rojo0a !important;
}

.notification-success .notification-message .title {
    color: $verde !important;
}

.notification-error::before {
    color: $rojo0a !important;
}

.notification-success::before {
    color: $verde !important;
}
