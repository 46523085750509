.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 1000000;
}

.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.ReactModal__Content {
    max-width: 100%;
    padding: 20px 10px 20px 10px !important;


    @media (min-width: 576px) {
        max-width: 85%;
        padding: 20px !important;
    }
}
