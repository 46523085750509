.app-carousel div:focus {
    outline: none !important;
}

.controls-wrapper {
    width: 100%;
}

.carousel-wrapper {
    width: 100%;
}

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
}

.suscription {
    margin: 20px;
    border-radius: 45px !important;
    min-width: 300px !important;
    max-width: 360px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-left: 0px !important;
}

.main-logo-timbre {
    max-width: 57px;
}



.titulo-plan {
    text-transform: uppercase;
}

.imakzv {
    margin: 0px !important;
}

.rec-carousel-item {
    align-items: center !important;
    display: flex !important;

}
