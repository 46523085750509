#select_xjf {
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    background-color: white !important;
    border-radius: 0.313rem !important;
    border: 0.125rem solid #7da04d67 !important;
    /* height: 2.5rem !important;
    min-height: unset !important; */
    border-radius: 8px !important;
    padding: 6px 9px 6px 5px !important;


}

#select_xjf:focus {
    outline: none !important;
}

/* b2d4ff*/

#select_xjf option:checked {
    background-color: #888888 !important;
    color: #ffffff;
}

#select_xjf option:hover {
    background-color: #7EA04D !important;
}

#select_xjf option:focus {
    background-color: #7EA04D !important;
}

#select_xjf option:focus-visible {
    background-color: #7EA04D !important;
}
