table {
    width: 100%;
    table-layout: fixed;
}
table,
th,
td {
    border: 1px solid #ffffff;
    border-collapse: collapse;
}

th,
td {
    /* padding: 5px; */
    text-align: left;
}
#t01 tr:nth-child(even) {
    background-color: #eee;
}
#t01 tr:nth-child(odd) {
    background-color: #fff;
}
.tc1 {
    background-color: #323232 !important;
    color: white;
    border-radius: 0.5rem 0.5rem;
}
