.icon-menu-dash {
    height: 45px;
    margin-right: 40px;
    margin-left: 8px;
    transition: transform 0.2s;
}

@media screen and (max-width: 480px) {
    .apexcharts-inner{
        display: none;
    }
}