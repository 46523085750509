@import '../../../../../../../style/configs/breakpoints.css';
@import '../../../../../../../style/configs/color.css';

$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

/* Card */
.card-flujo {
    /* min-width: 150px !important;
    max-width: 200px !important; */
    min-width: 160px !important;
    max-width: 200px !important;
    transition: $transition;
    background: #fff;
    margin: 20px 10px;
    cursor: pointer;

    @media (max-width: $sm) {
        min-width: 150px !important;
        max-width: 500px !important;
    }
}

/* Selectable */
$check-size: 20px;
$selected-color: #cd0a0a;

div .selectable {
    position: relative;
    transition: $transition;
    border-radius: 0.625rem;
    border: 2px solid #7ea04d;

    .check {
        transition: $transition;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        width: $check-size;
        height: $check-size;

        &:before {
            transition: $transition;
            content: '';
            border: $check-size solid;
            border-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }

        .checkmark {
            display: block;
            font: $check-size sans-serif;
            line-height: $check-size;
            text-align: center;
            color: transparent;
        }
    }

    &.selected {
        border: 2px solid $selected-color;

        /* border-color: $selected-color; */
        .check {
            &:before {
                border-color: $selected-color $selected-color rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
            }

            .checkmark {
                color: #fff;
            }
        }
    }
}

.content {

    .title,
    .description {
        margin: 0;
        padding: 4px;
    }

    padding: 24px;
}

/* Create four equal columns that floats next to each other */
.column {
    float: left;
    width: 25%;
    padding: 20px;
}

/* Clear floats after the columns */
.row:after {
    content: '';
    display: flex;
    clear: both;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
    .column {
        width: 50%;
    }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}
