$rojo0a: #cd0a0a;
$rojo45: #cd4545;
$rojo39: #ef4339;
$azul: #487e95;
$verde: #7ea04d;
$negro: #000000;
$gris: #888888;
$negro32: #323232;
$grisd7: #d7d7d7;

$azul52: #214252;
$azul48: #487E95;

$cian: #366aed;
$rojo: #d92027;

$gris_table: #f1f1f1;

/* Clases de colores para texto */

.blanco {
    color: white !important;
}

.azul48 {
    color: $azul48 !important;
}

.azul52 {
    color: $azul52 !important;
}

.rojo {
    color: $rojo !important;
}

.rojo0a {
    color: $rojo0a !important;
}

.rojo45 {
    color: $rojo45 !important;
}

.rojo39 {
    color: $rojo39 !important;
}

.azul {
    color: $azul !important;
}

.verde {
    color: $verde !important;
}

.negro {
    color: $negro !important;
}

.gris {
    color: $gris !important;
}

.negro32 {
    color: $negro32 !important;
}

.grisd7 {
    color: $grisd7 !important;
}

/* Fondo de los colores */

.bg-blanco {
    background-color: white !important;
}

.bg-rojo {
    background: $rojo !important;
}

.bg-rojo0a {
    background-color: $rojo0a !important;
}

.bg-rojo45 {
    background-color: $rojo45 !important;
}

.bg-rojo39 {
    background-color: $rojo39 !important;
}

.bg-azul {
    background-color: $azul !important;
}

.bg-verde {
    background-color: $verde !important;
}

.bg-negro {
    background-color: $negro !important;
}

.bg-gris {
    background-color: $gris !important;
}

.bg-negro32 {
    background-color: $negro32 !important;
}

.bg-grisd7 {
    background-color: $grisd7 !important;
}
