@import '../configs/color.css';
@import '../configs/breakpoints.css';

.main-sidebar {
    top: 0;
    position: fixed;
    height: 100vh;
    background: transparent !important;
    box-shadow: none !important;
    z-index: 100;
    will-change: transform;
    transition: -webkit-transform 0.2s ease-in-out;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    width: 66px;
    transition: width 0.3s;
}

/*OPCIONES DEL SUBMENU*/
.acordion-expanded {
    background-color: rgba(255, 255, 255, 0);
    margin-top: -13px;
    padding-top: 13px;
    margin-left: 20px;
    border-radius: 0 0 0 1rem;
}

.sub-nav-item a {
    font-size: 10px !important;
    margin-left: 55px;

    &.active {
        background-color: $rojo0a;
        color: white !important;
        margin-left: 55px;
        margin-right: 0px;
        padding: 0.3rem 0.7rem;
    }
}

.main-sidebar.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.main-sidebar .toggle-sidebar:hover {
    cursor: pointer;
}

.main-sidebar .navbar-brand {
    overflow: hidden;
    height: 7rem;
}

.main-sidebar .nav-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 175px) !important;
    border-radius: 10px;
    background: transparent;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 5px;
    padding-left: 0px;
    /* Ocultar el Scroll en Firefox, e Internet Explorer 10+ */
    scrollbar-width: none;
    -ms-overflow-style: none;

    /* Ocultar el Scroll en Chrome y Safari */
    &::-webkit-scrollbar {
        display: none;
    }
}

.main-sidebar .nav-wrapper .nav-item .accordion__item .acordion-expanded {
    height: 0px;
    overflow: hidden;
}

.main-sidebar .nav-wrapper .nav--no-borders .nav-item .accordion__item :focus {
    outline: none !important;
}

.main-sidebar .nav-wrapper:hover .nav-item .accordion__item .acordion-expanded {
    height: auto;
    overflow: hidden;
}

.main-sidebar .nav .nav-item,
.main-sidebar .nav .nav-link {
    white-space: nowrap;
    font-size: 16px !important;
}

.main-sidebar .nav .nav-item .nav-link {
    border-bottom: 1px solid #e1e5eb;
    font-weight: 400;
    color: $negro;
    padding: 0.5rem 0.8625rem !important;
}

.main-sidebar .nav .nav-item .nav-link i {
    min-width: 1.25rem;
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    will-change: color;
    color: #cacedb;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
    margin-right: 0.375rem;
}

.main-sidebar .nav .nav-item .nav-link i.material-icons {
    font-size: 1.125rem;
    top: -1px;
}

.nav-link {
    color: $negro !important;
    padding: 0.5rem 0.7rem;
    display: flex;
    /* span {
        margin-left: 7px;
    } */

    &.active {
        background-color: #fbfbfb !important;
        color: $gris;
        text-transform: uppercase;
    }
}

.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item.active span,
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link.active span,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item:hover,
.main-sidebar .nav .nav-item span:hover {
    background-color: #5c575700 !important;
    color: $rojo0a !important;
    /* text-transform: uppercase; */
    box-shadow: inset 0.1875rem 0 0 #007bff00 !important;
}

.nav-link:hover {
    color: $verde;
    cursor: pointer;
}

.icon-menu-profile {
    vertical-align: middle;
    object-fit: cover;
    border-radius: 50%;
    height: 43px;
    width: 43px;
    margin-right: 40px;
    margin-left: 0px;
}

.icon-menu-close {
    height: 40px;
    margin-right: 40px;
    margin-left: 5px;
}

.icon-menu {
    height: 30px;
    margin-right: 40px;
    margin-left: 5px;
    transition: transform 0.2s;
}

.icon-menu:hover {
    transform: scale(1.3);
    filter: invert(100%) sepia(99%) saturate(10%) hue-rotate(34deg) brightness(1000%) contrast(80%);
}

.icon-menu-sub {
    height: 8px;
    margin-right: 10px;
}

.main-logo-sidebar {
    max-width: 112px;
    max-height: 100px;
    box-shadow: 0 0 10px #dadbdb;
    border-radius: 10%;
}

.sidebar-cont {
    display: flex;
    position: relative;
    width: 75px !important;
    height: 100%;
    overflow: hidden;
    will-change: transform;
    transition: -webkit-transform 0.4s ease-in-out;
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    transition: width 0.5s;
}

.sidebar-cont:hover {
    width: 226px !important;
    display: block;
}

.sidebar-cont-icon {
    margin-top: 3px;
    width: 76px;
    height: 99vh;
    /* centrado vertical */
    position: absolute;
    background-color: $negro;
    box-shadow: none;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
}

.sidebar-cont-opc {
    margin-top: 3px;
    margin-left: 76px;
    width: 150px;
    height: 99vh;
    position: absolute;
    background-color: #fff;
    box-shadow: 2px 3px 5px #5e616340;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
}

.sidebar-principal {
    margin-top: 3px;
    width: 226px;
    height: 99vh;
    /* width: 100%; */
    position: absolute;
    background-color: #ffffff00;
    box-shadow: 0px 0px 7px #ffffff00;
    border-radius: 10px;
    opacity: 1;
    z-index: 1;
}

.spacing-items {
    margin-top: 110px;
}

.pie-sidebar {
    position: absolute;
    bottom: 20px;
}

.pie-sidebar a {
    color: $negro;
    padding: 0.5375rem 0.7625rem !important;
}

.pie-sidebar a:hover {
    color: $rojo0a;
}

.main-content {
    margin-left: 66px;

    @media (max-width: calc($md - 1px)) {
        margin-left: 0px;
    }
}

/* @media only screen and (max-width: $sm) {

    .main-sidebar:hover {
        width: 100% !important;
    }

    .sidebar-cont-opc {
        margin-top: 0px;
        width: 100%;
        height: 100vh;
    }

    .sidebar-principal {
        width: 100% !important;
        margin-top: 0px;
        height: 100vh;
    }
} */

@media only screen and (max-width: 767px) {
    /* For md: */

    .main-sidebar {
        width: 100% !important;
    }

    .sidebar-cont {
        width: 100% !important;
        margin-top: 0px;
    }

    .sidebar-cont:hover {
        width: 100% !important;
    }

    .sidebar-cont-icon {
        margin-top: 0px;
        height: 100vh;
        border-radius: 0px;
    }

    .sidebar-cont-opc {
        width: calc(100% - 76px) !important;
        margin-top: 0px;
        height: 100vh;
        border-radius: 0px;
    }

    .sidebar-principal {
        width: 100% !important;
        margin-top: 0px;
        height: 100vh;
    }
}
